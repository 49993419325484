import { connect } from 'react-redux';

import { settingsSelector } from '../behaviors/settings';
import { configSelector } from '../behaviors/configuration';
import { searchWidgetConfigSelector } from '../behaviors/search-widget';
import { getCustomerCode } from '../behaviors/customer-code';
import HomeV9 from '../home-v9';

const mapStateToProps = (state) => {
  const settings = settingsSelector(state);
  const config = configSelector(state);
  const searchWidgetConfig = searchWidgetConfigSelector(state);
  const customerCode = getCustomerCode(state);

  return {
    settings,
    config,
    searchWidgetConfig,
    customerCode
  };
};

const HomeContainerV9 = connect(mapStateToProps)(HomeV9);

export default HomeContainerV9;

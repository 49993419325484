import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const Header = styled.div`
  font-size: ${fromTheme('font_size_heading_5')};
  font-weight: ${fromTheme('font_weight_bolder')};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
